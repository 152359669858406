#unity_wrap{ width:100%; height:100%; position:relative;
  canvas{ position:absolute; top:0; left:0; width:100%; height:100%; }
}

#unity_help{
  position:absolute;
  top:0; 
  left: 0;

  button.closer{
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

#unity_help_inner{
  padding-left:6rem;
  padding-right:6rem;
  overflow:auto; 
  max-height:100%; 

  h5, h6{ font-weight:bold; }
  img{ width:48px; }
  li{ font-size:.9rem; }

  div.content{ min-height:48px; display:flex; flex-direction:column; justify-content:center; }
}
