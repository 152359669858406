
#tool_list{ 
  display: flex;
  flex-direction: column;

  & > .tool{ 
    margin-bottom:-1px;
    border-top:1px solid #ddd;

    &:first-child{ border-top:0; }
  }
}

#embedded_node_list{
  font-size:.8rem;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

.embedded-node-battery{
  i{ 
    line-height: 1; 
  }

  i.fa-battery-empty{
    animation: blink 1s steps(5, start) infinite;
    color: $danger !important;
  }
}

.tool-title{
  cursor: pointer;
  
  button{ 
    outline:none !important; 
    box-shadow:none !important; 
    display:block; 
  }

  i.caret{ 
    opacity: 0.5;
    transition:transform 200ms linear; 
  }
  
  h6[aria-expanded=false] ~ i.caret{
    transform:rotate(-90deg); 
  }
}

.dmm-chart, .dmm-chart-wrap{ height:80px; }

.tool-expand{
  &.-expanded{ 
    position:fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background:rgba(white, 0.8);

    .tool-expand-inner{
      margin: 5vh 10vw;
      position:relative;
      z-index:1001;
    }
  }

  button.close{ 
    text-align:center;
    line-height:1; 
    font-size:16px;
    position:absolute; 
    top:0;
    right:0;
    width:3rem;
    height:3rem;
    border:0; 
    outline:0;
    opacity:1;
    flex-direction:column;
    justify-content:center;
    align-items:center;

  }
}