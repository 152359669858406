#assets{
  min-height: 100%;

  .asset-filter-label{
    display: block;
    font-size:.6rem;
    text-transform: uppercase;
  }
}

#assetList{
  max-height: 90%;
  overflow: auto;
  display: flex;


  &.list{
    flex-direction: column;

    li.asset{
      flex-direction: row;
    }

    i.icon{
      margin-right: .5rem;
    }
  }

  &.grid{
    flex-direction: row; 
    flex-wrap: wrap;

    li.asset{
      width: 50%;
      flex-direction: column; 
      padding-right: 1rem;
      text-align:center;
    }

    i.icon{
      font-size:2rem;
    }

    span.filename{ 
      font-size: .72rem; 
    }
  }
}

.asset-filename, .smaller{ 
  font-size: .8rem; 
}

div.video-thumb{
  width: 80px;
  height: 60px;
  position:relative;
  color:white;
  i {
    position:absolute;
    top:50%;
    left:50%;
    opacity:0.5;
    transform:translate(-50%, -50%);
  }
}

#assetInput{
  position: relative;
  cursor: pointer;

  i{
    cursor:pointer;
  }

  input[type=file]{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    display: block;
    opacity: 0;
  }

  input[type=file], input[type=file]::-webkit-file-upload-button{
    cursor:pointer;
  }
}

// .upload-input{
//   position:relative;
//   cursor: pointer;

//   label, .file-input{
//     cursor: pointer;
//   }

//   div.form-control-progress{
//     position:absolute;
//     left:0;
//     bottom:0;
//     height: 5px;
//     background-color:$secondary;
//   }

//   i, span{ 
//     cursor: pointer;
//     z-index:10;
//   }

//   input[type=file]{
//     z-index:80;
//     top: 0;
//     left:0;
//     right:0;
//     bottom:0;
//     opacity: 0;
//     font-size:0;
//   }

//   input[type=file], input[type=file]::-webkit-file-upload-button{
//     cursor:pointer;
//   }
// }