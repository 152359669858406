#call_screen{ 
  background: darken($primary-light, 50%); 
  width: 100%;
  min-height: 100%;
  transition: $left-anim;
  position:relative;
  flex: 1;
  flex-basis: 100%;

  &[data-primary=video]{ 
    #video_main{ 
      height: calc(100% - 4.5rem);
    }
    #unity_main{ height:0%; }
  }

  &[data-primary=unity]{ 
    #unity_main{ 
      height: calc(100% - 4.5rem);
    }
    #video_main{ height:0%; }
  }

  &[data-primary=split]{ 
    #unity_main{ height:50%; }
    #video_main{ height:50%; }
  }
}

#video_main, #unity_main{
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
  transition: height .1s ease-in-out, width .25s ease-in-out;
}

#video_toggle{ 
  position:absolute; 
  z-index:50;
  bottom:1rem;
  left: 1rem;
  right: 1rem;
  
  button{ 
    text-align:center;
    box-shadow:0 0 2px 0px rgba(black, 0.2); 
    width:2.5rem;
    height:2.5rem;
  }
}

#video_main{
  display:flex;
  flex-direction: column;
}

#video_grid{
  height: 100%;
  display: grid;
  grid-template-columns: repeat(var(--video-cols), minmax(0, 1fr));
  grid-template-rows: repeat(var(--video-rows), 1fr);
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  align-items:stretch;
  justify-items: center;

  &[data-pinned=true] {
    grid-template-rows: 72% repeat(calc(var(--video-rows) - 1), 1fr); 
  }

  & > .item{
    position: relative;
    min-height: 100%;
    min-width: 100%;
    margin: 0 auto;
  }

  & > .pinned{
    order: 0;
    grid-row: 1;
    grid-column: 1 / span var(--video-cols);
  }

  & > .local:not(.pinned){
    order: var(--video-count);
  }

  &[data-count='1'], &[data-count='2'] {
    display: flex !important;
    position: relative;
  }
  
  &[data-count='2']:not([data-compact=true]){    

    & > .item:not(.pinned) {
      position: absolute;
      top: 2rem;
      bottom: auto;
      z-index: 40;
      right: 1rem;
      min-width: 24%;
      width: 24%;
      aspect-ratio: 16/9;
      min-height: 100px;
      max-height: 230px;
      box-shadow: 0px 0px 4px 1px rgba(black, 0.5);
      order: 2;
    }

    & > .pinned, &[data-pinned=false] > .local:not(.pinned){
      width: 100%;
      order: 1;
      flex-grow:1;
      z-index: 30;
    }
  }
}

#call_screen[data-compact=true]{
  width: 16%;
  flex-basis: 16%;

  #video_toggle{
    display: none !important;
  }

  #video_grid{
    display: flex;
    flex-direction: column;
    //grid-template-columns: repeat(1, 1fr) !important;

    & > .item{
      width: 100%;
      min-height: auto;
      aspect-ratio: 16/9;
    }

    .peer-user > figure > video{
      height: 100%;
      //width: 100%;
    }
  }

  #video_main{
    height: 100%;
    overflow-y: auto;
  }
  

}

#localVideoWrap{
  &.border{
    border-width:3px !important;
  }
  &.border-dark{
    border-color:$peer-video-bg !important;
  }
}


.peer-user{
  //aspect-ratio: 16/9;
  overflow: visible;
  max-height: 100%;
  margin:0px auto;
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  

  figure.video{
    box-sizing:border-box;
    background: black;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    video {
      object-fit: fill;
      min-height: 100%;
      min-width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:after{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display:block;
    pointer-events:none;
  }

  &.-speaking:after{
    border:3px solid black;
    border-color:$secondary !important;
  }


  &:not(.-connected):not(.local-user){
    .peer-avatar img{
      opacity: 0.4;
    }
  }

  .peer-options{
    position: absolute;
    left: 0;
    top: 0;

    .dropdown-toggle{
      text-shadow:0px 0px 4px rgba(black, 0.5);
      &:after{ 
        display: none;
      }
    }
  }
  
  .peer-indicator{ 
    color:white; 
    position:absolute; 
    top:50%; 
    left:0; 
    right: 0;
    transform:translateY(-50%); 
  }
  
  .peer-status-indicators{ 
    position:absolute;
    top: auto !important;
    left: auto !important;
    bottom: 8px !important;
    right: 8px !important;

    i{
      position:static !important;
      transform: none !important;
      text-shadow: rgba(black, 0.3) 1px 1px 4px;
    }
  }

  .local-controls{
    position:absolute; 
    bottom:.5rem; 
    right:0; 
    left: 0;
    justify-content: end;
    pointer-events:all; 
    
    button{ 
      position:relative; 
      top:0; 
      right:0; 
      width:24px; 
      height: 24px;
      padding:0 .5rem 0;
    }

    i{
      font-size:.8rem;
      text-shadow: rgba(black, 0.3) 1px 1px 4px;
    }
  }
}

.peer-avatar{
  justify-content:center;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  img{
    max-width:80% !important;
    max-height:50%;
  }
  background:$peer-video-bg;
}

// .peer-user-enter, .peer-user-appear{ 
//   transform: translateX(100%);
// }

// .peer-user-enter-active, .peer-user-appear-active{
//   transition-timing-function: ease-out;
//   transform: translateX(0%);
// }

// .peer-user-exit{
//   transform: translateX(0%);
// }

// .peer-user-exit-active{
//   transition-timing-function: ease-in;
//   transform: translateX(100%);
// }