$enable-rounded:false;
$font-family-sans-serif:'Mulish', sans-serif;
$navbar-height:4rem;

$primary:#184891;
$secondary:#53a1d1;
$dark:#091930;
$primary-light:desaturate(lighten($primary, 25%), 60%);
$secondary-light:desaturate(lighten($secondary, 30%), 10%);

@import '~bootstrap/scss/bootstrap.scss';

$peer-video-bg:$gray-800;
$slide-duration:200ms;
$left-anim: width .25s ease-in-out, flex-basis .25s ease-in-out;

:root {
  --toastify-color-progress-light: #{$secondary} !important;
  --video-cols:4;
  --video-rows:2;
}


//--------------------
// General

body{ overflow-x:hidden !important; }
.fa-inline, .fa-inline-brand{
  &::before{ font-weight:900 !important; margin-right:.5em; font-family:'Font Awesome 5 Free'; -webkit-font-smoothing:antialiased; font-style:normal; }
}

figure.video{ margin:0;
  button{ position:absolute; bottom:.5rem; right:.5rem; }
  figcaption{ position:absolute; top:0; right:0; padding:.25rem .5rem; background:white; text-transform:uppercase; font-size:.5rem; opacity:0.5; }
}

.btn, .btn:focus, .btn:active{ outline:0px !important; box-shadow:none;}
.bg-black{ background-color:black; }


button.toolbar-button{ 
  border-color:darken($dark, 7%); 
  border-width:0 1px 0 1px; margin:0 -1px 0 0; 
  height:$navbar-height; 
  transform:translateY(-1px); 
  outline:0px !important;
  box-shadow:none !important;
  position:relative;
  backface-visibility: hidden;
  
  &.disabled, &:disabled{ 
    opacity:0.25; 
  }

  small.badge{
    position:absolute; 
    top:20%;
    right:.5rem; 
    font-size:.64rem;
    line-height:0.8;
  }

  &.-active, &.popover-btn[aria-describedby]{
    
    &:after{
      content: " ";
      display:block;
      width: 100%;
      height: 3px;
      background: white;
      position: absolute;
      bottom:-2px;
      left: 0;
      right: 0;
    }
  }

  &.-active.panel:after{
    content:" ";
    display:block;
    height:1px;
    background:white;
    position:absolute;
    top:100%;
    left:0;
    right:0;
  }

  &:last-child{ border-right-width:1px; }
}

.btn-40{ 
  width: 40px;
  height: 40px;
}

.btn-48{
  width:48px !important;
  height:48px !important;
}

.btn-32{ 
  width: 32px;
  height: 32px;
}

.btn-link{
  &:hover, &:focus{ text-decoration:none; }
}

.bg-gray{ background:$gray-400; }
video{ background:$gray-600; margin:0; line-height:1; }

.bg-primary-light{ background-color:$primary-light; }
.bg-secondary-light{ background-color:$secondary-light; }
.text-strike {
  text-decoration: line-through;
}


//--------------------

#logo{ 
  span{ 
    font-size:64%; 
    margin-left:-4%;
    position:relative;
    top:-12%;
  }
}

#preload{
  background:rgba($dark, 0.9);
  color:white;
  position:fixed;
  top:0;
  left:0;
  width: 100vw;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}


//------------------------------------------------------------------------------------------
// Main area with isolated views ( login panel etc )
//------------------------------------------------------------------------------------------

#connection_main{
  display:flex;
  height: 100%;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  flex-grow:1;
  height: 100vh;
  width:100vw;
  background:$dark;

  & > .panel{
    width: 90vw; 
    max-width: 480px;
    min-height:300px;
    background:white;
    transform:width .2s linear, height .2s linear;

    &.-wide{
      max-width:800px;
      min-height:auto !important;
    }
  }
}


#connect{ width:90vw; max-width:480px; min-height:300px;  
  .input-group{ max-width:300px; position:relative; 
    &.select{ max-width:90%; }
  }
  input{ outline:none !important; box-shadow:none !important; }//border-color:$input-border-color; }
  h1, h2, h3, h4{ font-weight:300; }
  span.copied{ position:absolute; top:-1rem; right:-.25rem; opacity:0; transition:opacity .25s ease-in-out, top .25s ease-in-out; font-size:.75rem; padding:0 .25rem;
    color:white; border-radius:.25rem; 
    &.show{ opacity:1; top: -1.5rem; }
  }
}

#lobby {
  width: 90vw;
  max-width: 900px;
}


@import './dashboard';

#app{ 
  display:flex; 
  flex-direction:column; 
  height:100vh; 
  overflow:hidden; 
}

#toolbar{ 
  border-bottom:1px solid darken($dark, 7%) !important;
  .navbar-tools{ 
    background-color:rgba(black, 0.125); 
  }

  .navbar-brand{
    min-width:10rem;
    img{
      max-width:100%;
    }
  }

  span.room-name{ 
    max-width: 4rem; 
  }
}

#toolbar, #bottomBar{
  height:$navbar-height; 
  z-index: 10;
  position: relative;
}

.toolbar-panel{ 
  position:fixed; 
  z-index:9; 
  top:3.5rem; 
  left:0; 
  right:0;
  transition: right $slide-duration ease-in-out; 
}

.toolbar-panel-inner{
  max-height: calc(100vh - 3.5rem);
}

#object_library{
  .model{ 
    position:relative; 
    width:8rem;    

    &.-processing{
      color: #ccc;
    }
    div.progress-indicator{
      position:absolute;
      top:50%;
      left: 8px; 
      right: 8px;
      transform:translateY(-50%);
    }
  }

  .model-button{
    position:absolute; 
    top:0; 
    left:0; 
    right:0; 
    bottom:0; 
    z-index:2; 
    width:100%; 
    padding:0; 
    display:block; 
    opacity:0; 
  }

  .model-name{
    word-wrap: break-word;
  }
}

#room_desc{ 
  span.badge-dark{ background:$gray-600; font-weight:normal; }
}

#main{ 
  display: flex; 
  flex: 1; 
  flex-direction: row; 
  flex-wrap: nowrap; 
  height: calc(100vh - (#{$navbar-height} * 2));
}


#left, #right{ 
  min-height: 100%; 
  display: flex; 
  flex-direction: column; 
  transition: width $slide-duration ease-in-out; 
}

#left{ 
  flex-direction: row; 
  box-shadow:0px 0px 8px 2px rgba($primary, 0.4); 
  position:relative;
  z-index:5; 
  flex-grow:1; 
  flex-shrink:0; 
  width:100vw;
}

#right{ 
  flex-grow:1; 
  flex-shrink:1; 
  max-height:calc(100vh - #{$navbar-height});
  overflow-x:hidden;
  overflow-y:auto;
  
  & .card{ 
    border-left-width:0px; 
    border-radius:0; 
  }
  
  .card-title{ 
    font-size:.8rem; 
  }

}

#app.-sidebar{
  #left{ 
    border-right:1px solid rgba($primary, 0.65);
    width: 24vw; 
  }
  #right{ 
    width: 76vw; 
  }

  .toolbar-panel{ right:76vw; }
}

#right.sidebar-closed{
  .sidebar-panel{
    opacity: 0;
  }
}

#screen_tools{
  z-index: 60;
  background: white;
  transition: $left-anim;
  overflow:hidden;
  flex: 1;

  &.closed{
    width: 0%;
    flex-basis: 0%;
  }

  &.open{
    width: 84%;
    flex-basis: 84%;
  }

  & > .screen-tool{
    width: 100%;
    min-height: 100%;
    position:relative;
    
    img{ 
      max-width:100%; 
      max-height:100%; 
    }
  }

  .nav-link.active{
    border-top:0px !important;
    border-bottom-width:2px;
  }

  .nav-tabs .nav-link:not(.active){
    color:rgba(black, 0.4);
  }

  button.closer{
    width: 2.2em;
  }
}

#pdf_viewer{
  height:100%;
  button.closer{ bottom:1rem; top:auto; }
}

@import './assets';

.screen-tool{
  overflow: hidden;
  height: 100%;

  .screen-tool-toolbar{
    z-index:65;
  }
  
  .screen-tool-loader{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .screenshot-img{
    min-height: 200px;
  }

  & > .draw-mode{
    padding-top:3.5rem;
    overflow-y:auto;
    overflow-x: hidden;
  }

}

.draw-mode-arrows{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  input {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

.draw-mode-canvas{
  overflow-y: auto;
  overflow-x: hidden;

  .draw-mode_image{
    min-height: 300px;
    background: black;
  }
}

.draw-arrow {
  .arrow-anchor {
    position: absolute; 
    bottom: 0;
    left: 50%;
    right: 50%;
  }
}

.draw-toolbar { 
  .draw-toolbar-selected-peer {
    max-width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
  }
}

.draw-arrow_image {
  height: 3em;
  display: flex;
  flex-direction: column;
  
  i { 
    line-height: 0.5; 
    margin-top: auto;
    align-self: center;
  }
}

#draw_arrow_container{
  overflow: hidden;
}

@import './callScreen';
@import './unity';


.card + .card{ margin-top:-1px; }

#chat{ 
  min-height:100%;
}

#chat_log{ 
  overflow:auto; 
  max-height:70vh; 
  font-size:.8rem; 
}

.chat-message{
  h6{ font-size:0.8rem; }
}

.chat-msg-toast{
  line-height: 1.25;
}


@import './toolbox';

[data-toggle=collapse], .collapse-toggle{
  outline:0px !important;

  i.caret{ 
    opacity: 0.5;
    transition:transform 200ms linear; 
  }
  
  &[aria-expanded=false] i.caret{
    transform:rotate(-90deg); 
  }
}


#status_pane{
  max-height:100%;
  overflow: auto;
  
  i.status-indicator{
    font-size:.8rem;
  }
  
  font-size:.8rem;
}

@include media-breakpoint-up('md'){
  #main{
    height: calc(100vh - #{$navbar-height});
  }

  #app.-sidebar{
    #left{ 
      width: 72vw; 
    }
    #right{ 
      width: 26vw; 
    }
  
    .toolbar-panel{ right:26vw; }
  }

  #toolbar{
    span.room-name{ 
      max-width: 12rem; 
    }
  }
}

@include media-breakpoint-up('lg'){
  #app.-sidebar{
    #left{ 
      flex-grow:1; 
      width: 78vw;
    }
    #right{ 
      width:22vw; 
    }
    .toolbar-panel{ 
      right:22vw; 
    }
  }
}