#dash_user_detail{
  background-color: $secondary-light;
  border-bottom:1px solid darken($secondary-light, 10%);
}

.room-info{
  .room-info-inner{ 
    min-height:100%; 
  }
}

#av_test_video {
  .controls {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }

  button {
    position: static !important;
  }
}

#lobby {
  #av_test_devices {
    flex-direction: column;

    .input-group {
      margin: 0 !important;

      & + .input-group{ 
        margin-top: .25rem !important; 
      }
    }
  }
}